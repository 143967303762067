(function ($) {
    $.extend($.summernote.lang, {
        'nl-NL': {
            font: {
                bold: 'Vet',
                italic: 'Cursief',
                underline: 'Onderstrepen',
                clear: 'Stijl verwijderen',
                height: 'Regelhoogte',
                name: 'Lettertype',
                strikethrough: 'Doorhalen',
                size: 'Tekstgrootte'
            },
            image: {
                image: 'Afbeelding',
                insert: 'Afbeelding invoegen',
                resizeFull: 'Volledige breedte',
                resizeHalf: 'Halve breedte',
                resizeQuarter: 'Kwart breedte',
                floatLeft: 'Links uitlijnen',
                floatRight: 'Rechts uitlijnen',
                floatNone: 'Geen uitlijning',
                dragImageHere: 'Sleep hier een afbeelding naar toe',
                selectFromFiles: 'Selecteer een bestand',
                url: 'URL van de afbeelding',
                remove: 'Verwijder afbeelding'
            },
            video: {
                video: 'Video',
                videoLink: 'Video link',
                insert: 'Video invoegen',
                url: 'URL van de video',
                providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion of Youku)'
            },
            link: {
                link: 'Link',
                insert: 'Link invoegen',
                unlink: 'Link verwijderen',
                edit: 'Wijzigen',
                textToDisplay: 'Tekst van link',
                url: 'Naar welke URL moet deze link verwijzen?',
                openInNewWindow: 'Open in nieuw venster'
            },
            table: {
                table: 'Tabel'
            },
            hr: {
                insert: 'Horizontale lijn invoegen'
            },
            style: {
                style: 'Stijl',
                p: 'Normaal',
                blockquote: 'Quote',
                pre: 'Code',
                h1: 'Kop 1',
                h2: 'Kop 2',
                h3: 'Kop 3',
                h4: 'Kop 4',
                h5: 'Kop 5',
                h6: 'Kop 6'
            },
            lists: {
                unordered: 'Ongeordende lijst',
                ordered: 'Geordende lijst'
            },
            options: {
                help: 'Help',
                fullscreen: 'Volledig scherm',
                codeview: 'Bekijk Code'
            },
            paragraph: {
                paragraph: 'Paragraaf',
                outdent: 'Inspringen verkleinen',
                indent: 'Inspringen vergroten',
                left: 'Links uitlijnen',
                center: 'Centreren',
                right: 'Rechts uitlijnen',
                justify: 'Uitvullen'
            },
            color: {
                recent: 'Recente kleur',
                more: 'Meer kleuren',
                background: 'Achtergrond kleur',
                foreground: 'Tekst kleur',
                transparent: 'Transparant',
                setTransparent: 'Transparant',
                reset: 'Standaard',
                resetToDefault: 'Standaard kleur'
            },
            shortcut: {
                shortcuts: 'Toetsencombinaties',
                close: 'sluiten',
                textFormatting: 'Tekststijlen',
                action: 'Acties',
                paragraphFormatting: 'Paragraafstijlen',
                documentStyle: 'Documentstijlen'
            },
            history: {
                undo: 'Ongedaan maken',
                redo: 'Opnieuw doorvoeren'
            }
        }
    });
})(jQuery);
