;(function(window, document, undefined) {
    "use strict";

    (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
        'use strict';

        var _interopRequireWildcard = function (obj) { return obj && obj.__esModule ? obj : { 'default': obj }; };

        Object.defineProperty(exports, '__esModule', {
            value: true
        });
// SweetAlert
// 2014-2015 (c) - Tristan Edwards
// github.com/t4t5/sweetalert

        /*
         * jQuery-like functions for manipulating the DOM
         */

        var _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation = require('./modules/handle-dom');

        /*
         * Handy utilities
         */

        var _extend$hexToRgb$isIE8$logStr$colorLuminance = require('./modules/utils');

        /*
         *  Handle sweetAlert's DOM elements
         */

        var _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition = require('./modules/handle-swal-dom');

// Handle button events and keyboard events

        var _handleButton$handleConfirm$handleCancel = require('./modules/handle-click');

        var _handleKeyDown = require('./modules/handle-key');

        var _handleKeyDown2 = _interopRequireWildcard(_handleKeyDown);

// Default values

        var _defaultParams = require('./modules/default-params');

        var _defaultParams2 = _interopRequireWildcard(_defaultParams);

        var _setParameters = require('./modules/set-params');

        var _setParameters2 = _interopRequireWildcard(_setParameters);

        /*
         * Remember state in cases where opening and handling a modal will fiddle with it.
         * (We also use window.previousActiveElement as a global variable)
         */
        var previousWindowKeyDown;
        var lastFocusedButton;

        /*
         * Global sweetAlert function
         * (this is what the user calls)
         */
        var sweetAlert, swal;

        exports['default'] = sweetAlert = swal = function () {
            var customizations = arguments[0];

            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.addClass(document.body, 'stop-scrolling');
            _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.resetInput();

            /*
             * Use argument if defined or default value from params object otherwise.
             * Supports the case where a default value is boolean true and should be
             * overridden by a corresponding explicit argument which is boolean false.
             */
            function argumentOrDefault(key) {
                var args = customizations;
                return args[key] === undefined ? _defaultParams2['default'][key] : args[key];
            }

            if (customizations === undefined) {
                _extend$hexToRgb$isIE8$logStr$colorLuminance.logStr('SweetAlert expects at least 1 attribute!');
                return false;
            }

            var params = _extend$hexToRgb$isIE8$logStr$colorLuminance.extend({}, _defaultParams2['default']);

            switch (typeof customizations) {

                // Ex: swal("Hello", "Just testing", "info");
                case 'string':
                    params.title = customizations;
                    params.text = arguments[1] || '';
                    params.type = arguments[2] || '';
                    break;

                // Ex: swal({ title:"Hello", text: "Just testing", type: "info" });
                case 'object':
                    if (customizations.title === undefined) {
                        _extend$hexToRgb$isIE8$logStr$colorLuminance.logStr('Missing "title" argument!');
                        return false;
                    }

                    params.title = customizations.title;

                    for (var customName in _defaultParams2['default']) {
                        params[customName] = argumentOrDefault(customName);
                    }

                    // Show "Confirm" instead of "OK" if cancel button is visible
                    params.confirmButtonText = params.showCancelButton ? 'Confirm' : _defaultParams2['default'].confirmButtonText;
                    params.confirmButtonText = argumentOrDefault('confirmButtonText');

                    // Callback function when clicking on "OK"/"Cancel"
                    params.doneFunction = arguments[1] || null;

                    break;

                default:
                    _extend$hexToRgb$isIE8$logStr$colorLuminance.logStr('Unexpected type of argument! Expected "string" or "object", got ' + typeof customizations);
                    return false;

            }

            _setParameters2['default'](params);
            _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.fixVerticalPosition();
            _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.openModal(arguments[1]);

            // Modal interactions
            var modal = _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getModal();

            /*
             * Make sure all modal buttons respond to all events
             */
            var $buttons = modal.querySelectorAll('button');
            var buttonEvents = ['onclick', 'onmouseover', 'onmouseout', 'onmousedown', 'onmouseup', 'onfocus'];
            var onButtonEvent = function onButtonEvent(e) {
                return _handleButton$handleConfirm$handleCancel.handleButton(e, params, modal);
            };

            for (var btnIndex = 0; btnIndex < $buttons.length; btnIndex++) {
                for (var evtIndex = 0; evtIndex < buttonEvents.length; evtIndex++) {
                    var btnEvt = buttonEvents[evtIndex];
                    $buttons[btnIndex][btnEvt] = onButtonEvent;
                }
            }

            // Clicking outside the modal dismisses it (if allowed by user)
            _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getOverlay().onclick = onButtonEvent;

            previousWindowKeyDown = window.onkeydown;

            var onKeyEvent = function onKeyEvent(e) {
                return _handleKeyDown2['default'](e, params, modal);
            };
            window.onkeydown = onKeyEvent;

            window.onfocus = function () {
                // When the user has focused away and focused back from the whole window.
                setTimeout(function () {
                    // Put in a timeout to jump out of the event sequence.
                    // Calling focus() in the event sequence confuses things.
                    if (lastFocusedButton !== undefined) {
                        lastFocusedButton.focus();
                        lastFocusedButton = undefined;
                    }
                }, 0);
            };

            // Show alert with enabled buttons always
            swal.enableButtons();
        };

        /*
         * Set default params for each popup
         * @param {Object} userParams
         */
        sweetAlert.setDefaults = swal.setDefaults = function (userParams) {
            if (!userParams) {
                throw new Error('userParams is required');
            }
            if (typeof userParams !== 'object') {
                throw new Error('userParams has to be a object');
            }

            _extend$hexToRgb$isIE8$logStr$colorLuminance.extend(_defaultParams2['default'], userParams);
        };

        /*
         * Animation when closing modal
         */
        sweetAlert.close = swal.close = function () {
            var modal = _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getModal();

            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.fadeOut(_sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getOverlay(), 5);
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.fadeOut(modal, 5);
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass(modal, 'showSweetAlert');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.addClass(modal, 'hideSweetAlert');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass(modal, 'visible');

            /*
             * Reset icon animations
             */
            var $successIcon = modal.querySelector('.sa-icon.sa-success');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($successIcon, 'animate');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($successIcon.querySelector('.sa-tip'), 'animateSuccessTip');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($successIcon.querySelector('.sa-long'), 'animateSuccessLong');

            var $errorIcon = modal.querySelector('.sa-icon.sa-error');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($errorIcon, 'animateErrorIcon');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($errorIcon.querySelector('.sa-x-mark'), 'animateXMark');

            var $warningIcon = modal.querySelector('.sa-icon.sa-warning');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($warningIcon, 'pulseWarning');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($warningIcon.querySelector('.sa-body'), 'pulseWarningIns');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($warningIcon.querySelector('.sa-dot'), 'pulseWarningIns');

            // Reset custom class (delay so that UI changes aren't visible)
            setTimeout(function () {
                var customClass = modal.getAttribute('data-custom-class');
                _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass(modal, customClass);
            }, 300);

            // Make page scrollable again
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass(document.body, 'stop-scrolling');

            // Reset the page to its previous state
            window.onkeydown = previousWindowKeyDown;
            if (window.previousActiveElement) {
                window.previousActiveElement.focus();
            }
            lastFocusedButton = undefined;
            clearTimeout(modal.timeout);

            return true;
        };

        /*
         * Validation of the input field is done by user
         * If something is wrong => call showInputError with errorMessage
         */
        sweetAlert.showInputError = swal.showInputError = function (errorMessage) {
            var modal = _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getModal();

            var $errorIcon = modal.querySelector('.sa-input-error');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.addClass($errorIcon, 'show');

            var $errorContainer = modal.querySelector('.sa-error-container');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.addClass($errorContainer, 'show');

            $errorContainer.querySelector('p').innerHTML = errorMessage;

            setTimeout(function () {
                sweetAlert.enableButtons();
            }, 1);

            modal.querySelector('input').focus();
        };

        /*
         * Reset input error DOM elements
         */
        sweetAlert.resetInputError = swal.resetInputError = function (event) {
            // If press enter => ignore
            if (event && event.keyCode === 13) {
                return false;
            }

            var $modal = _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getModal();

            var $errorIcon = $modal.querySelector('.sa-input-error');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($errorIcon, 'show');

            var $errorContainer = $modal.querySelector('.sa-error-container');
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide$isDescendant$getTopMargin$fadeIn$fadeOut$fireClick$stopEventPropagation.removeClass($errorContainer, 'show');
        };

        /*
         * Disable confirm and cancel buttons
         */
        sweetAlert.disableButtons = swal.disableButtons = function (event) {
            var modal = _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getModal();
            var $confirmButton = modal.querySelector('button.confirm');
            var $cancelButton = modal.querySelector('button.cancel');
            $confirmButton.disabled = true;
            $cancelButton.disabled = true;
        };

        /*
         * Enable confirm and cancel buttons
         */
        sweetAlert.enableButtons = swal.enableButtons = function (event) {
            var modal = _sweetAlertInitialize$getModal$getOverlay$getInput$setFocusStyle$openModal$resetInput$fixVerticalPosition.getModal();
            var $confirmButton = modal.querySelector('button.confirm');
            var $cancelButton = modal.querySelector('button.cancel');
            $confirmButton.disabled = false;
            $cancelButton.disabled = false;
        };

        if (typeof window !== 'undefined') {
            // The 'handle-click' module requires
            // that 'sweetAlert' was set as global.
            window.sweetAlert = window.swal = sweetAlert;
        } else {
            _extend$hexToRgb$isIE8$logStr$colorLuminance.logStr('SweetAlert is a frontend module!');
        }
        module.exports = exports['default'];

    },{"./modules/default-params":2,"./modules/handle-click":3,"./modules/handle-dom":4,"./modules/handle-key":5,"./modules/handle-swal-dom":6,"./modules/set-params":8,"./modules/utils":9}],2:[function(require,module,exports){
        'use strict';

        Object.defineProperty(exports, '__esModule', {
            value: true
        });
        var defaultParams = {
            title: '',
            text: '',
            type: null,
            allowOutsideClick: false,
            showConfirmButton: true,
            showCancelButton: false,
            closeOnConfirm: true,
            closeOnCancel: true,
            confirmButtonText: 'OK',
            confirmButtonColor: '#8CD4F5',
            cancelButtonText: 'Cancel',
            imageUrl: null,
            imageSize: null,
            timer: null,
            customClass: '',
            html: false,
            animation: true,
            allowEscapeKey: true,
            inputType: 'text',
            inputPlaceholder: '',
            inputValue: '',
            showLoaderOnConfirm: false
        };

        exports['default'] = defaultParams;
        module.exports = exports['default'];

    },{}],3:[function(require,module,exports){
        'use strict';

        Object.defineProperty(exports, '__esModule', {
            value: true
        });

        var _colorLuminance = require('./utils');

        var _getModal = require('./handle-swal-dom');

        var _hasClass$isDescendant = require('./handle-dom');

        /*
         * User clicked on "Confirm"/"OK" or "Cancel"
         */
        var handleButton = function handleButton(event, params, modal) {
            var e = event || window.event;
            var target = e.target || e.srcElement;

            var targetedConfirm = target.className.indexOf('confirm') !== -1;
            var targetedOverlay = target.className.indexOf('sweet-overlay') !== -1;
            var modalIsVisible = _hasClass$isDescendant.hasClass(modal, 'visible');
            var doneFunctionExists = params.doneFunction && modal.getAttribute('data-has-done-function') === 'true';

            // Since the user can change the background-color of the confirm button programmatically,
            // we must calculate what the color should be on hover/active
            var normalColor, hoverColor, activeColor;
            if (targetedConfirm && params.confirmButtonColor) {
                normalColor = params.confirmButtonColor;
                hoverColor = _colorLuminance.colorLuminance(normalColor, -0.04);
                activeColor = _colorLuminance.colorLuminance(normalColor, -0.14);
            }

            function shouldSetConfirmButtonColor(color) {
                if (targetedConfirm && params.confirmButtonColor) {
                    target.style.backgroundColor = color;
                }
            }

            switch (e.type) {
                case 'mouseover':
                    shouldSetConfirmButtonColor(hoverColor);
                    break;

                case 'mouseout':
                    shouldSetConfirmButtonColor(normalColor);
                    break;

                case 'mousedown':
                    shouldSetConfirmButtonColor(activeColor);
                    break;

                case 'mouseup':
                    shouldSetConfirmButtonColor(hoverColor);
                    break;

                case 'focus':
                    var $confirmButton = modal.querySelector('button.confirm');
                    var $cancelButton = modal.querySelector('button.cancel');

                    if (targetedConfirm) {
                        $cancelButton.style.boxShadow = 'none';
                    } else {
                        $confirmButton.style.boxShadow = 'none';
                    }
                    break;

                case 'click':
                    var clickedOnModal = modal === target;
                    var clickedOnModalChild = _hasClass$isDescendant.isDescendant(modal, target);

                    // Ignore click outside if allowOutsideClick is false
                    if (!clickedOnModal && !clickedOnModalChild && modalIsVisible && !params.allowOutsideClick) {
                        break;
                    }

                    if (targetedConfirm && doneFunctionExists && modalIsVisible) {
                        handleConfirm(modal, params);
                    } else if (doneFunctionExists && modalIsVisible || targetedOverlay) {
                        handleCancel(modal, params);
                    } else if (_hasClass$isDescendant.isDescendant(modal, target) && target.tagName === 'BUTTON') {
                        sweetAlert.close();
                    }
                    break;
            }
        };

        /*
         *  User clicked on "Confirm"/"OK"
         */
        var handleConfirm = function handleConfirm(modal, params) {
            var callbackValue = true;

            if (_hasClass$isDescendant.hasClass(modal, 'show-input')) {
                callbackValue = modal.querySelector('input').value;

                if (!callbackValue) {
                    callbackValue = '';
                }
            }

            params.doneFunction(callbackValue);

            if (params.closeOnConfirm) {
                sweetAlert.close();
            }
            // Disable cancel and confirm button if the parameter is true
            if (params.showLoaderOnConfirm) {
                sweetAlert.disableButtons();
            }
        };

        /*
         *  User clicked on "Cancel"
         */
        var handleCancel = function handleCancel(modal, params) {
            // Check if callback function expects a parameter (to track cancel actions)
            var functionAsStr = String(params.doneFunction).replace(/\s/g, '');
            var functionHandlesCancel = functionAsStr.substring(0, 9) === 'function(' && functionAsStr.substring(9, 10) !== ')';

            if (functionHandlesCancel) {
                params.doneFunction(false);
            }

            if (params.closeOnCancel) {
                sweetAlert.close();
            }
        };

        exports['default'] = {
            handleButton: handleButton,
            handleConfirm: handleConfirm,
            handleCancel: handleCancel
        };
        module.exports = exports['default'];

    },{"./handle-dom":4,"./handle-swal-dom":6,"./utils":9}],4:[function(require,module,exports){
        'use strict';

        Object.defineProperty(exports, '__esModule', {
            value: true
        });
        var hasClass = function hasClass(elem, className) {
            return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
        };

        var addClass = function addClass(elem, className) {
            if (!hasClass(elem, className)) {
                elem.className += ' ' + className;
            }
        };

        var removeClass = function removeClass(elem, className) {
            var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
            if (hasClass(elem, className)) {
                while (newClass.indexOf(' ' + className + ' ') >= 0) {
                    newClass = newClass.replace(' ' + className + ' ', ' ');
                }
                elem.className = newClass.replace(/^\s+|\s+$/g, '');
            }
        };

        var escapeHtml = function escapeHtml(str) {
            var div = document.createElement('div');
            div.appendChild(document.createTextNode(str));
            return div.innerHTML;
        };

        var _show = function _show(elem) {
            elem.style.opacity = '';
            elem.style.display = 'block';
        };

        var show = function show(elems) {
            if (elems && !elems.length) {
                return _show(elems);
            }
            for (var i = 0; i < elems.length; ++i) {
                _show(elems[i]);
            }
        };

        var _hide = function _hide(elem) {
            elem.style.opacity = '';
            elem.style.display = 'none';
        };

        var hide = function hide(elems) {
            if (elems && !elems.length) {
                return _hide(elems);
            }
            for (var i = 0; i < elems.length; ++i) {
                _hide(elems[i]);
            }
        };

        var isDescendant = function isDescendant(parent, child) {
            var node = child.parentNode;
            while (node !== null) {
                if (node === parent) {
                    return true;
                }
                node = node.parentNode;
            }
            return false;
        };

        var getTopMargin = function getTopMargin(elem) {
            elem.style.left = '-9999px';
            elem.style.display = 'block';

            var height = elem.clientHeight,
                padding;
            if (typeof getComputedStyle !== 'undefined') {
                // IE 8
                padding = parseInt(getComputedStyle(elem).getPropertyValue('padding-top'), 10);
            } else {
                padding = parseInt(elem.currentStyle.padding);
            }

            elem.style.left = '';
            elem.style.display = 'none';
            return '-' + parseInt((height + padding) / 2) + 'px';
        };

        var fadeIn = function fadeIn(elem, interval) {
            if (+elem.style.opacity < 1) {
                interval = interval || 16;
                elem.style.opacity = 0;
                elem.style.display = 'block';
                var last = +new Date();
                var tick = (function (_tick) {
                    function tick() {
                        return _tick.apply(this, arguments);
                    }

                    tick.toString = function () {
                        return _tick.toString();
                    };

                    return tick;
                })(function () {
                    elem.style.opacity = +elem.style.opacity + (new Date() - last) / 100;
                    last = +new Date();

                    if (+elem.style.opacity < 1) {
                        setTimeout(tick, interval);
                    }
                });
                tick();
            }
            elem.style.display = 'block'; //fallback IE8
        };

        var fadeOut = function fadeOut(elem, interval) {
            interval = interval || 16;
            elem.style.opacity = 1;
            var last = +new Date();
            var tick = (function (_tick2) {
                function tick() {
                    return _tick2.apply(this, arguments);
                }

                tick.toString = function () {
                    return _tick2.toString();
                };

                return tick;
            })(function () {
                elem.style.opacity = +elem.style.opacity - (new Date() - last) / 100;
                last = +new Date();

                if (+elem.style.opacity > 0) {
                    setTimeout(tick, interval);
                } else {
                    elem.style.display = 'none';
                }
            });
            tick();
        };

        var fireClick = function fireClick(node) {
            // Taken from http://www.nonobtrusive.com/2011/11/29/programatically-fire-crossbrowser-click-event-with-javascript/
            // Then fixed for today's Chrome browser.
            if (typeof MouseEvent === 'function') {
                // Up-to-date approach
                var mevt = new MouseEvent('click', {
                    view: window,
                    bubbles: false,
                    cancelable: true
                });
                node.dispatchEvent(mevt);
            } else if (document.createEvent) {
                // Fallback
                var evt = document.createEvent('MouseEvents');
                evt.initEvent('click', false, false);
                node.dispatchEvent(evt);
            } else if (document.createEventObject) {
                node.fireEvent('onclick');
            } else if (typeof node.onclick === 'function') {
                node.onclick();
            }
        };

        var stopEventPropagation = function stopEventPropagation(e) {
            // In particular, make sure the space bar doesn't scroll the main window.
            if (typeof e.stopPropagation === 'function') {
                e.stopPropagation();
                e.preventDefault();
            } else if (window.event && window.event.hasOwnProperty('cancelBubble')) {
                window.event.cancelBubble = true;
            }
        };

        exports.hasClass = hasClass;
        exports.addClass = addClass;
        exports.removeClass = removeClass;
        exports.escapeHtml = escapeHtml;
        exports._show = _show;
        exports.show = show;
        exports._hide = _hide;
        exports.hide = hide;
        exports.isDescendant = isDescendant;
        exports.getTopMargin = getTopMargin;
        exports.fadeIn = fadeIn;
        exports.fadeOut = fadeOut;
        exports.fireClick = fireClick;
        exports.stopEventPropagation = stopEventPropagation;

    },{}],5:[function(require,module,exports){
        'use strict';

        Object.defineProperty(exports, '__esModule', {
            value: true
        });

        var _stopEventPropagation$fireClick = require('./handle-dom');

        var _setFocusStyle = require('./handle-swal-dom');

        var handleKeyDown = function handleKeyDown(event, params, modal) {
            var e = event || window.event;
            var keyCode = e.keyCode || e.which;

            var $okButton = modal.querySelector('button.confirm');
            var $cancelButton = modal.querySelector('button.cancel');
            var $modalButtons = modal.querySelectorAll('button[tabindex]');

            if ([9, 13, 32, 27].indexOf(keyCode) === -1) {
                // Don't do work on keys we don't care about.
                return;
            }

            var $targetElement = e.target || e.srcElement;

            var btnIndex = -1; // Find the button - note, this is a nodelist, not an array.
            for (var i = 0; i < $modalButtons.length; i++) {
                if ($targetElement === $modalButtons[i]) {
                    btnIndex = i;
                    break;
                }
            }

            if (keyCode === 9) {
                // TAB
                if (btnIndex === -1) {
                    // No button focused. Jump to the confirm button.
                    $targetElement = $okButton;
                } else {
                    // Cycle to the next button
                    if (btnIndex === $modalButtons.length - 1) {
                        $targetElement = $modalButtons[0];
                    } else {
                        $targetElement = $modalButtons[btnIndex + 1];
                    }
                }

                _stopEventPropagation$fireClick.stopEventPropagation(e);
                $targetElement.focus();

                if (params.confirmButtonColor) {
                    _setFocusStyle.setFocusStyle($targetElement, params.confirmButtonColor);
                }
            } else {
                if (keyCode === 13) {
                    if ($targetElement.tagName === 'INPUT') {
                        $targetElement = $okButton;
                        $okButton.focus();
                    }

                    if (btnIndex === -1) {
                        // ENTER/SPACE clicked outside of a button.
                        $targetElement = $okButton;
                    } else {
                        // Do nothing - let the browser handle it.
                        $targetElement = undefined;
                    }
                } else if (keyCode === 27 && params.allowEscapeKey === true) {
                    $targetElement = $cancelButton;
                    _stopEventPropagation$fireClick.fireClick($targetElement, e);
                } else {
                    // Fallback - let the browser handle it.
                    $targetElement = undefined;
                }
            }
        };

        exports['default'] = handleKeyDown;
        module.exports = exports['default'];

    },{"./handle-dom":4,"./handle-swal-dom":6}],6:[function(require,module,exports){
        'use strict';

        var _interopRequireWildcard = function (obj) { return obj && obj.__esModule ? obj : { 'default': obj }; };

        Object.defineProperty(exports, '__esModule', {
            value: true
        });

        var _hexToRgb = require('./utils');

        var _removeClass$getTopMargin$fadeIn$show$addClass = require('./handle-dom');

        var _defaultParams = require('./default-params');

        var _defaultParams2 = _interopRequireWildcard(_defaultParams);

        /*
         * Add modal + overlay to DOM
         */

        var _injectedHTML = require('./injected-html');

        var _injectedHTML2 = _interopRequireWildcard(_injectedHTML);

        var modalClass = '.sweet-alert';
        var overlayClass = '.sweet-overlay';

        var sweetAlertInitialize = function sweetAlertInitialize() {
            var sweetWrap = document.createElement('div');
            sweetWrap.innerHTML = _injectedHTML2['default'];

            // Append elements to body
            while (sweetWrap.firstChild) {
                document.body.appendChild(sweetWrap.firstChild);
            }
        };

        /*
         * Get DOM element of modal
         */
        var getModal = (function (_getModal) {
            function getModal() {
                return _getModal.apply(this, arguments);
            }

            getModal.toString = function () {
                return _getModal.toString();
            };

            return getModal;
        })(function () {
            var $modal = document.querySelector(modalClass);

            if (!$modal) {
                sweetAlertInitialize();
                $modal = getModal();
            }

            return $modal;
        });

        /*
         * Get DOM element of input (in modal)
         */
        var getInput = function getInput() {
            var $modal = getModal();
            if ($modal) {
                return $modal.querySelector('input');
            }
        };

        /*
         * Get DOM element of overlay
         */
        var getOverlay = function getOverlay() {
            return document.querySelector(overlayClass);
        };

        /*
         * Add box-shadow style to button (depending on its chosen bg-color)
         */
        var setFocusStyle = function setFocusStyle($button, bgColor) {
            var rgbColor = _hexToRgb.hexToRgb(bgColor);
            $button.style.boxShadow = '0 0 2px rgba(' + rgbColor + ', 0.8), inset 0 0 0 1px rgba(0, 0, 0, 0.05)';
        };

        /*
         * Animation when opening modal
         */
        var openModal = function openModal(callback) {
            var $modal = getModal();
            _removeClass$getTopMargin$fadeIn$show$addClass.fadeIn(getOverlay(), 10);
            _removeClass$getTopMargin$fadeIn$show$addClass.show($modal);
            _removeClass$getTopMargin$fadeIn$show$addClass.addClass($modal, 'showSweetAlert');
            _removeClass$getTopMargin$fadeIn$show$addClass.removeClass($modal, 'hideSweetAlert');

            window.previousActiveElement = document.activeElement;
            var $okButton = $modal.querySelector('button.confirm');
            $okButton.focus();

            setTimeout(function () {
                _removeClass$getTopMargin$fadeIn$show$addClass.addClass($modal, 'visible');
            }, 500);

            var timer = $modal.getAttribute('data-timer');

            if (timer !== 'null' && timer !== '') {
                var timerCallback = callback;
                $modal.timeout = setTimeout(function () {
                    var doneFunctionExists = (timerCallback || null) && $modal.getAttribute('data-has-done-function') === 'true';
                    if (doneFunctionExists) {
                        timerCallback(null);
                    } else {
                        sweetAlert.close();
                    }
                }, timer);
            }
        };

        /*
         * Reset the styling of the input
         * (for example if errors have been shown)
         */
        var resetInput = function resetInput() {
            var $modal = getModal();
            var $input = getInput();

            _removeClass$getTopMargin$fadeIn$show$addClass.removeClass($modal, 'show-input');
            $input.value = _defaultParams2['default'].inputValue;
            $input.setAttribute('type', _defaultParams2['default'].inputType);
            $input.setAttribute('placeholder', _defaultParams2['default'].inputPlaceholder);

            resetInputError();
        };

        var resetInputError = function resetInputError(event) {
            // If press enter => ignore
            if (event && event.keyCode === 13) {
                return false;
            }

            var $modal = getModal();

            var $errorIcon = $modal.querySelector('.sa-input-error');
            _removeClass$getTopMargin$fadeIn$show$addClass.removeClass($errorIcon, 'show');

            var $errorContainer = $modal.querySelector('.sa-error-container');
            _removeClass$getTopMargin$fadeIn$show$addClass.removeClass($errorContainer, 'show');
        };

        /*
         * Set "margin-top"-property on modal based on its computed height
         */
        var fixVerticalPosition = function fixVerticalPosition() {
            var $modal = getModal();
            $modal.style.marginTop = _removeClass$getTopMargin$fadeIn$show$addClass.getTopMargin(getModal());
        };

        exports.sweetAlertInitialize = sweetAlertInitialize;
        exports.getModal = getModal;
        exports.getOverlay = getOverlay;
        exports.getInput = getInput;
        exports.setFocusStyle = setFocusStyle;
        exports.openModal = openModal;
        exports.resetInput = resetInput;
        exports.resetInputError = resetInputError;
        exports.fixVerticalPosition = fixVerticalPosition;

    },{"./default-params":2,"./handle-dom":4,"./injected-html":7,"./utils":9}],7:[function(require,module,exports){
        "use strict";

        Object.defineProperty(exports, "__esModule", {
            value: true
        });
        var injectedHTML =

// Dark overlay
                "<div class=\"sweet-overlay\" tabIndex=\"-1\"></div>" +

// Modal
                "<div class=\"sweet-alert\">" +

// Error icon
                "<div class=\"sa-icon sa-error\">\n      <span class=\"sa-x-mark\">\n        <span class=\"sa-line sa-left\"></span>\n        <span class=\"sa-line sa-right\"></span>\n      </span>\n    </div>" +

// Warning icon
                "<div class=\"sa-icon sa-warning\">\n      <span class=\"sa-body\"></span>\n      <span class=\"sa-dot\"></span>\n    </div>" +

// Info icon
                "<div class=\"sa-icon sa-info\"></div>" +

// Success icon
                "<div class=\"sa-icon sa-success\">\n      <span class=\"sa-line sa-tip\"></span>\n      <span class=\"sa-line sa-long\"></span>\n\n      <div class=\"sa-placeholder\"></div>\n      <div class=\"sa-fix\"></div>\n    </div>" + "<div class=\"sa-icon sa-custom\"></div>" +

// Title, text and input
                "<h2>Title</h2>\n    <p>Text</p>\n    <fieldset>\n      <input type=\"text\" tabIndex=\"3\" />\n      <div class=\"sa-input-error\"></div>\n    </fieldset>" +

// Input errors
                "<div class=\"sa-error-container\">\n      <div class=\"icon\">!</div>\n      <p>Not valid!</p>\n    </div>" +

// Cancel and confirm buttons
                "<div class=\"sa-button-container\">\n      <button class=\"cancel\" tabIndex=\"2\">Cancel</button>\n      <div class=\"sa-confirm-button-container\">\n        <button class=\"confirm\" tabIndex=\"1\">OK</button>" +

// Loading animation
                "<div class=\"la-ball-fall\">\n          <div></div>\n          <div></div>\n          <div></div>\n        </div>\n      </div>\n    </div>" +

// End of modal
                "</div>";

        exports["default"] = injectedHTML;
        module.exports = exports["default"];

    },{}],8:[function(require,module,exports){
        'use strict';

        Object.defineProperty(exports, '__esModule', {
            value: true
        });

        var _isIE8 = require('./utils');

        var _getModal$getInput$setFocusStyle = require('./handle-swal-dom');

        var _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide = require('./handle-dom');

        var alertTypes = ['error', 'warning', 'info', 'success', 'input', 'prompt'];

        /*
         * Set type, text and actions on modal
         */
        var setParameters = function setParameters(params) {
            var modal = _getModal$getInput$setFocusStyle.getModal();

            var $title = modal.querySelector('h2');
            var $text = modal.querySelector('p');
            var $cancelBtn = modal.querySelector('button.cancel');
            var $confirmBtn = modal.querySelector('button.confirm');

            /*
             * Title
             */
            $title.innerHTML = params.html ? params.title : _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.escapeHtml(params.title).split('\n').join('<br>');

            /*
             * Text
             */
            $text.innerHTML = params.html ? params.text : _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.escapeHtml(params.text || '').split('\n').join('<br>');
            if (params.text) _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.show($text);

            /*
             * Custom class
             */
            if (params.customClass) {
                _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass(modal, params.customClass);
                modal.setAttribute('data-custom-class', params.customClass);
            } else {
                // Find previously set classes and remove them
                var customClass = modal.getAttribute('data-custom-class');
                _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.removeClass(modal, customClass);
                modal.setAttribute('data-custom-class', '');
            }

            /*
             * Icon
             */
            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.hide(modal.querySelectorAll('.sa-icon'));

            if (params.type && !_isIE8.isIE8()) {
                var _ret = (function () {

                    var validType = false;

                    for (var i = 0; i < alertTypes.length; i++) {
                        if (params.type === alertTypes[i]) {
                            validType = true;
                            break;
                        }
                    }

                    if (!validType) {
                        logStr('Unknown alert type: ' + params.type);
                        return {
                            v: false
                        };
                    }

                    var typesWithIcons = ['success', 'error', 'warning', 'info'];
                    var $icon = undefined;

                    if (typesWithIcons.indexOf(params.type) !== -1) {
                        $icon = modal.querySelector('.sa-icon.' + 'sa-' + params.type);
                        _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.show($icon);
                    }

                    var $input = _getModal$getInput$setFocusStyle.getInput();

                    // Animate icon
                    switch (params.type) {

                        case 'success':
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon, 'animate');
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon.querySelector('.sa-tip'), 'animateSuccessTip');
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon.querySelector('.sa-long'), 'animateSuccessLong');
                            break;

                        case 'error':
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon, 'animateErrorIcon');
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon.querySelector('.sa-x-mark'), 'animateXMark');
                            break;

                        case 'warning':
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon, 'pulseWarning');
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon.querySelector('.sa-body'), 'pulseWarningIns');
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass($icon.querySelector('.sa-dot'), 'pulseWarningIns');
                            break;

                        case 'input':
                        case 'prompt':
                            $input.setAttribute('type', params.inputType);
                            $input.value = params.inputValue;
                            $input.setAttribute('placeholder', params.inputPlaceholder);
                            _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.addClass(modal, 'show-input');
                            setTimeout(function () {
                                $input.focus();
                                $input.addEventListener('keyup', swal.resetInputError);
                            }, 400);
                            break;
                    }
                })();

                if (typeof _ret === 'object') {
                    return _ret.v;
                }
            }

            /*
             * Custom image
             */
            if (params.imageUrl) {
                var $customIcon = modal.querySelector('.sa-icon.sa-custom');

                $customIcon.style.backgroundImage = 'url(' + params.imageUrl + ')';
                _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.show($customIcon);

                var _imgWidth = 80;
                var _imgHeight = 80;

                if (params.imageSize) {
                    var dimensions = params.imageSize.toString().split('x');
                    var imgWidth = dimensions[0];
                    var imgHeight = dimensions[1];

                    if (!imgWidth || !imgHeight) {
                        logStr('Parameter imageSize expects value with format WIDTHxHEIGHT, got ' + params.imageSize);
                    } else {
                        _imgWidth = imgWidth;
                        _imgHeight = imgHeight;
                    }
                }

                $customIcon.setAttribute('style', $customIcon.getAttribute('style') + 'width:' + _imgWidth + 'px; height:' + _imgHeight + 'px');
            }

            /*
             * Show cancel button?
             */
            modal.setAttribute('data-has-cancel-button', params.showCancelButton);
            if (params.showCancelButton) {
                $cancelBtn.style.display = 'inline-block';
            } else {
                _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.hide($cancelBtn);
            }

            /*
             * Show confirm button?
             */
            modal.setAttribute('data-has-confirm-button', params.showConfirmButton);
            if (params.showConfirmButton) {
                $confirmBtn.style.display = 'inline-block';
            } else {
                _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.hide($confirmBtn);
            }

            /*
             * Custom text on cancel/confirm buttons
             */
            if (params.cancelButtonText) {
                $cancelBtn.innerHTML = _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.escapeHtml(params.cancelButtonText);
            }
            if (params.confirmButtonText) {
                $confirmBtn.innerHTML = _hasClass$addClass$removeClass$escapeHtml$_show$show$_hide$hide.escapeHtml(params.confirmButtonText);
            }

            /*
             * Custom color on confirm button
             */
            if (params.confirmButtonColor) {
                // Set confirm button to selected background color
                $confirmBtn.style.backgroundColor = params.confirmButtonColor;

                // Set the confirm button color to the loading ring
                $confirmBtn.style.borderLeftColor = params.confirmLoadingButtonColor;
                $confirmBtn.style.borderRightColor = params.confirmLoadingButtonColor;

                // Set box-shadow to default focused button
                _getModal$getInput$setFocusStyle.setFocusStyle($confirmBtn, params.confirmButtonColor);
            }

            /*
             * Allow outside click
             */
            modal.setAttribute('data-allow-outside-click', params.allowOutsideClick);

            /*
             * Callback function
             */
            var hasDoneFunction = params.doneFunction ? true : false;
            modal.setAttribute('data-has-done-function', hasDoneFunction);

            /*
             * Animation
             */
            if (!params.animation) {
                modal.setAttribute('data-animation', 'none');
            } else if (typeof params.animation === 'string') {
                modal.setAttribute('data-animation', params.animation); // Custom animation
            } else {
                modal.setAttribute('data-animation', 'pop');
            }

            /*
             * Timer
             */
            modal.setAttribute('data-timer', params.timer);
        };

        exports['default'] = setParameters;
        module.exports = exports['default'];

    },{"./handle-dom":4,"./handle-swal-dom":6,"./utils":9}],9:[function(require,module,exports){
        'use strict';

        Object.defineProperty(exports, '__esModule', {
            value: true
        });
        /*
         * Allow user to pass their own params
         */
        var extend = function extend(a, b) {
            for (var key in b) {
                if (b.hasOwnProperty(key)) {
                    a[key] = b[key];
                }
            }
            return a;
        };

        /*
         * Convert HEX codes to RGB values (#000000 -> rgb(0,0,0))
         */
        var hexToRgb = function hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) : null;
        };

        /*
         * Check if the user is using Internet Explorer 8 (for fallbacks)
         */
        var isIE8 = function isIE8() {
            return window.attachEvent && !window.addEventListener;
        };

        /*
         * IE compatible logging for developers
         */
        var logStr = function logStr(string) {
            if (window.console) {
                // IE...
                window.console.log('SweetAlert: ' + string);
            }
        };

        /*
         * Set hover, active and focus-states for buttons
         * (source: http://www.sitepoint.com/javascript-generate-lighter-darker-color)
         */
        var colorLuminance = function colorLuminance(hex, lum) {
            // Validate hex string
            hex = String(hex).replace(/[^0-9a-f]/gi, '');
            if (hex.length < 6) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            lum = lum || 0;

            // Convert to decimal and change luminosity
            var rgb = '#';
            var c;
            var i;

            for (i = 0; i < 3; i++) {
                c = parseInt(hex.substr(i * 2, 2), 16);
                c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
                rgb += ('00' + c).substr(c.length);
            }

            return rgb;
        };

        exports.extend = extend;
        exports.hexToRgb = hexToRgb;
        exports.isIE8 = isIE8;
        exports.logStr = logStr;
        exports.colorLuminance = colorLuminance;

    },{}]},{},[1])

    /*
     * Use SweetAlert with RequireJS
     */

    if (typeof define === 'function' && define.amd) {
        define(function () {
            return sweetAlert;
        });
    } else if (typeof module !== 'undefined' && module.exports) {
        module.exports = sweetAlert;
    }

})(window, document);
