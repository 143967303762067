(function (jQuery) {
    var Mediapicker = function (node, options) {
        this.node = node;

        var plugin = this;

        this.options = jQuery.extend({
            api:         '',
            csrf:        '',
            canDelete:   true,
            canUpdate:   true,
            multiSelect: false
        }, options);

        this.callbacks = {
            selected:  function (item) {
                //
            },
            cancelled: function () {
                //
            }
        };

        // Lister for the mediapicker selected event
        this.node.on('mediapicker.selected', function (e, items) {
            plugin.node.modal('hide');

            if (plugin.callbacks.selected !== false) {
                plugin.callbacks.selected(items);
            }
        });

        // Listen for the mediapicker cancelled event
        this.node.on('mediapicker.cancelled', function (e) {
            plugin.node.modal('hide');

            if (plugin.callbacks.cancelled !== false) {
                plugin.callbacks.cancelled();
            }
        });

        var resizer = _.debounce(function () {
            var height = jQuery(window).height() / 100 * 65;

            plugin.node.find('.tab-content-wrapper').css('height', height);
            plugin.node.find('.media-info').css('height', height);
        }, 300);

        resizer();

        jQuery(window).resize(resizer);
    };

    Mediapicker.prototype = {

        constructor: Mediapicker,

        show: function () {
            this.node.trigger('medialibrary.settings', this.options);

            this.node.data({
                'api':         this.options.api,
                'csrf':        this.options.csrf,
                'canUpdate':   this.options.canUpdate,
                'canDelete':   this.options.canDelete,
                'multiselect': this.options.multiSelect
            });

            this.node.modal('show');
        },

        pick: function (callback) {
            this.callbacks.selected = callback;

            this.node.trigger('medialibrary.open');

            this.show();
        },

        hide: function () {
            this.node.modal('hide');
        }

    };

    jQuery.fn.mediapicker = function (options) {
        var target   = jQuery(this),
            instance = target.data('mediapicker');

        if (instance === undefined) {
            instance = new Mediapicker(this, options);

            target.data('mediapicker', instance);
        } else {
            if (options) {
                instance.node.data({
                    'types':           options.types,
                    'owner':           options.owner,
                    'transformations': options.transformations,
                });
            }
        }

        return instance;
    };
})(jQuery);
